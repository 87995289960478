import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { navigate } from 'gatsby';
import { BoardListItem } from '@mvrb-frontend/shared-ui';
import Skeleton from '@material-ui/lab/Skeleton';
import '../style/page.scss';
import { useBoards } from '../hooks';
import Map from '../utils/Map';
import Page from '../components/page';
import { ModalNewsLetter, NewsLetterSubmissionType, ReviewAlert } from '@mvrb-frontend/shared-ui';
import { getUser, isLoggedIn } from '../utils/auth';
import { useNewsLetterApi } from '../hooks';
import { NewsLetterSchema } from '../constants';
import Engagement from '../components/Engagement/Engagement';
import Home from '../components/Home/Home';

const styles = (theme: Theme) => ({
  topSection: {
    backgroundColor: theme.palette.common.white,
    boxShadow: `0 5px 5px 1px #CCCED0`,
    marginTop: '8px',
    paddingBottom: '10px',
  },
  biggerText: {
    fontSize: '16px',
  },
});

const IndexPage = (props: any) => {
  const { classes, data } = props;
  const [homeView, setHomeView] = useState('Home');

  const [openModal, setOpenModal] = useState(true);
  const [showSucces, setShowSuccess] = useState(false);
  const [successOptions, setSuccessOptions] = useState({ lwb: false, rboard: false });
  const LoggedIn = isLoggedIn();

  const closeModal = (subscription?: any, action?: string) => {
    setOpenModal(false);
    updateUserNewsLetter({ email: getUser().email, updatePreferences: true }, NewsLetterSubmissionType.UpdatePreferences);
    
    if (action === 'success') {
      setSuccessOptions(subscription);
      setShowSuccess(true);
    }
  }

  const {updateUserNewsLetter, getUserPreferences, getUserNewsLetter, dataNewsLetter, showNL} = useNewsLetterApi();

  useEffect(()=>{
    (async()=>{
      if(LoggedIn){
        await getUserNewsLetter({userEmail: getUser().email});
        await getUserPreferences({userEmail: getUser().email});
      }
    })();
  },[]);

  return (
    <>
      <Page isFetching={false} container={false}>
        <>
          {showSucces &&
            <ReviewAlert
              onClose={() => { }}
              isVisible={true}
              severity='success'
              alertTitle={'You were successfully subscribed to the following boards'}
              alertMessage={
                <>
                  <ul>
                    {successOptions.lwb &&
                      <li>LWBs</li>
                    }
                    {successOptions.rboard &&
                      <li>Review Board</li>
                    }
                  </ul>
                </>
              }
            />
          }
          {homeView === 'Home' ?
            (
              <Home 
                classes={classes}
                setHomeView={setHomeView}
              />
            ) : (
              <Engagement 
                setHomeView={setHomeView}
              />
            )}
          {openModal && LoggedIn && showNL &&
            <ModalNewsLetter
              onCancel={closeModal}
              isVisible={openModal}
              validationSchema={NewsLetterSchema}
              userName={`${getUser().firstName} ${getUser().lastName}`}
              email={getUser().email}
              submitData={updateUserNewsLetter}
              preferences={dataNewsLetter}
            />
          }
        </>
      </Page>
    </>
  );
};

IndexPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IndexPage);
