import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Button, Theme, makeStyles, Box, Container } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useGetEngagements } from "../../hooks";
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import EngagementAccordion from "./EngagementAccordion";
import Map from '../../utils/Map'
import EngagementMap from "./EngagementMap";

const useStyles = makeStyles(theme => ({
  biggerText: {
    fontSize: '16px',
  },
  accordionSummary: {
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    },
  },
  topSection: {
    backgroundColor: theme.palette.common.white,
    boxShadow: `0 5px 5px 1px #CCCED0`,
    marginTop: '8px',
    paddingBottom: '10px',
  },
  title: {
    display: 'flex',
    minHeight: '3rem',
  },
  boardListItemHeader: {
    color: '#39A9B0',
  },
  accordionDetails: {
    backgroundColor: '#F5FAFB',
    flexWrap: 'wrap',
    padding: '0px',
  },
  accordionDetailsBox: {
    width: '100%',
  },
  hr: {
    margin: '0',
    width: '100%',
    border: '1px #bbbbbb17 solid',
  },
  ulist:{
    fontSize: '15px',
    marginLeft: '1.5rem',
  },
  noResult:{
    textAlign: 'center',
    width: '100%',
    margin: '6.25rem'
  }
}))

const rowsPerPage = 24

const Engagement = (props: any) => {
  const {
    setHomeView,
  } = props;

  const classes = useStyles();
  const [engagementItems, setEngagementItems] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const { getEngagements, isFetching, engagementList, count } = useGetEngagements();
  const [columnCount, setColumnCount] = useState(count);
  const [open, setOpen] = useState<boolean>(false);
  const [ reset, setReset ] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const data = await getEngagements({ /* skip: 0, take: 25, */ engagementView: true });
      setEngagementItems(data);
    })()
  }, [])

  useEffect(() => {
    //Calculate the number of rows for the first and second accordion columns
    if (count !== 0) {
      const mod = count % 2;
      const division = Math.floor(count / 2) + mod;
      setColumnCount(division);
    }
  }, [count]);

  const searchQueryMap = async (mapLocation:string) => {
    setOpen(true);
    const data = await getEngagements({ engagementView: true, engagementSearch: mapLocation });
    setEngagementItems(data);
  }

  const paginateRows = async page => {
    //Function for pagination, currently hided.
    const newSkip = (page - 1) * rowsPerPage;
    history.replaceState({ ...history.state, currentPage: page }, document.title);
    setCurrentPage(page);
    setSkip(newSkip);
    const query = { skip: newSkip, take: rowsPerPage, engagementView: true };
    const data = await getEngagements(query);
    setEngagementItems(data);
  };

  return (
    <><Box className={classes.topSection}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          justify="center"
          className={'rootContainer'}
        >
          <Grid item xs={12} md={5} className={classes.biggerText}>
            <Typography variant="h1" style={{ fontSize: '35px' }}>Building Relationships with Potentially Affected Parties</Typography>
            <p>
              The benefits of effective engagement and consultation are 
              extensive and further detailed in the <a href="https://wlwb.ca/media/1835/download?inline" target="_blank" rel="noreferrer">LWB’s Engagement and Consultation Policy</a> (2023). Applicants are 
              required to initiate dialogue and engagement planning <b>early</b> with affected 
              parties, particularly affected Indigenous governments, in the early planning stages and well 
              in advance of an application with the goal of:
            </p>
            <p className={classes.ulist}>
              <ul>
                <li>identifying and establishing relationships;</li>
                <li>sharing information;</li>
                <li>identifying and understanding concerns, potential impacts (including any potential for impacts to Aboriginal and treaty rights), and interests;</li>
                <li>addressing concerns raised to the extent possible early in the pre-planning stage and/or planning when to address certain concerns over the life of a project;</li>
                <li>collaborating on project planning including planning for closure and reclamation (e.g. closure goals and objectives), monitoring, and mitigation design;</li>
                <li>identifying and tracking commitments; and </li>
                <li>ensuring appropriate levels and types of engagement are collaboratively planned for the life of the project.</li>
              </ul>
            </p>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disableElevation
              style={{marginTop:'1.6rem', textTransform:'none'}}
              onClick={() => { setHomeView('Home') }}
            >
              Switch View to Active Reviews
            </Button>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box p={2} style={{ textAlign: 'center' }}>
              <EngagementMap searchQueryMap={searchQueryMap} setReset={setReset} reset={reset}/>
            </Box>
          </Grid>
        </Grid>
      </Container>

    </Box>
      <Container maxWidth="lg" className={'root-container'}>
        <Grid
          container
          spacing={2}
          className={'rootContainer'}
        >
          <Grid item xs={12} md={12} className={classes.biggerText}>
            <div className={classes.title}>
              <Typography variant="h2" color="primary" style={{ marginRight: '1.5rem' }}>
                Early Engagement
              </Typography>
              <Button
                color="primary"
                variant="contained"
                size="large"
                style={{height:'2.5rem'}}
                onClick={() => {searchQueryMap(""); setReset(true); setOpen(false)}}
                disableElevation
              >
                View All Organizations
              </Button>
            </div>
            <p>
              When you select an area on the map above, the organizations who are potentially affected by proposed activities in that area will 
              be expanded below, so that their contact information appears. Please contact these organizations as part of your early engagement.
            </p>
          </Grid>
          <Grid container spacing={3} xs={12} md={12}>
            {isFetching ? (
              <>
                <Grid container item spacing={1} direction={"column"} xs={6}>
                  <Skeleton variant="text" height={60} />
                  <Skeleton variant="text" height={60} />
                  <Skeleton variant="text" height={60} />
                  <Skeleton variant="text" height={60} />
                  <Skeleton variant="text" height={60} />
                </Grid>
                <Grid container item spacing={1} direction={"column"} xs={6}>
                  <Skeleton variant="text" height={60} />
                  <Skeleton variant="text" height={60} />
                  <Skeleton variant="text" height={60} />
                  <Skeleton variant="text" height={60} />
                  <Skeleton variant="text" height={60} />
                </Grid>
              </>
            ) : (
              <>
                {engagementItems?.length > 0 ? (
                    <>
                      <Grid container item spacing={2} direction={"column"} xs={6}>
                        {engagementItems?.length > 0 &&
                          engagementItems.map((item, key) => {
                            if (key + 1 <= columnCount) {
                              return (
                                <EngagementAccordion
                                  itemData={item}
                                  key={key}
                                  open={open}
                                />
                              )
                            }
                          })
                        }
                      </Grid>
                      <Grid container item spacing={2} direction={"column"} xs={6}>
                        {engagementItems?.length > 0 &&
                          engagementItems.map((item, key) => {
                            if (key + 1 > columnCount) {
                              return (
                                <EngagementAccordion
                                  itemData={item}
                                  key={key}
                                  open={open}
                                />
                              )
                            }
                          })
                        }
                      </Grid>
                    </>
                ) : (
                  <>
                    <Typography className={classes.noResult} variant="h3">
                      No Engagement Organization Found
                    </Typography>
                  </>
                )}

                
                {/* 
                    **keep pagination for future development if needed** 

                    <Grid container xs={12} md={12}>
                    <Pagination
                      count={Math.ceil(count / rowsPerPage)}
                      variant="outlined"
                      shape="rounded"
                      color="primary"
                      size="small"
                      disabled={Math.ceil(count/ rowsPerPage) <= 1}
                      showFirstButton
                      showLastButton
                      page={currentPage}
                      onChange={(event, value) => paginateRows(value)}
                    />
                  </Grid> */}
              </>
            )}
          </Grid>

        </Grid>
      </Container>
    </>
  )
}

Engagement.propTypes = {
  setHomeView: PropTypes.any,
}

export default Engagement;