import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { navigate } from 'gatsby';
import { BoardListItem } from '@mvrb-frontend/shared-ui';
import Skeleton from '@material-ui/lab/Skeleton';
import '../../style/page.scss';
import { useBoards } from '../../hooks';
import Map from '../../utils/Map';



const styles = (theme: Theme) => ({
  topSection: {
    backgroundColor: theme.palette.common.white,
    boxShadow: `0 5px 5px 1px #CCCED0`,
    marginTop: '8px',
    paddingBottom: '10px',
  },
  biggerText: {
    fontSize: '16px',
  },
});

const Home = (props: any) => {
  const { getBoards, isFetchingBoards } = useBoards();
  const [boards, setBoards] = useState([]);

  const {
    classes,
    setHomeView,
  } = props;

  useEffect(() => {
    (async () => {
      const res = await getBoards({
        getActiveReviewCounts: true,
        getImages: true,
        includePosition: true,
      });
      if (res && res.data) {
        setBoards(res.data);
      }
    })();
  }, []);

  const getListOfBoards = () => {
    return boards.map(
      ({ id, name, abbreviation, imageBase64, imageMime, reviewCount, orgId }) => {

        const abbr = orgId === null ? '(' + abbreviation + ')' : '';

        return (
          <BoardListItem
            key={id}
            boardName={`${name} ${abbr}`}
            imageBase64={imageBase64}
            imageMime={imageMime}
            reviewCount={reviewCount}
            linkTo={() =>
              navigate(
                `/reviews/board/${encodeURIComponent(
                  abbreviation.toLowerCase(),
                )}`,
              )
            }
          />
        );
      },
    );
  };
  return (

    <>
      <Box className={classes.topSection}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            className={'rootContainer'}
          >
            <Grid item xs={12} md={5} className={classes.biggerText}>
              <Typography variant="h1">Welcome to the ORS</Typography>
              <p>
                Welcome to the Online Review System which provides everyone an
                opportunity to participate in the Boards&apos; public review
                process.
              </p>
              <p>
                Co-management is the foundation of the northern regulatory
                system so it&apos;s important that parties take the time to
                contribute their knowledge, perspectives, and expertise to the
                decision-making process.
              </p>
              {/* <Button
                color="primary"
                variant="contained"
                size="large"
                disableElevation
                onClick={() => { setHomeView('Engagement') }}
              >
                Switch view to Early Engagement
              </Button> */}
              <Button
                color="primary"
                variant="contained"
                size="large"
                disableElevation
                onClick={() =>
                  window.open(
                    process.env.GATSBY_USER_MANUAL_PDF_URL,
                    '_blank',
                  )
                }
              >
                User Manual
              </Button>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box p={2} style={{ textAlign: 'center' }}>
                <Map />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box >
      <Container maxWidth="lg" className={'root-container'}>
        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="baseline"
          className={'rootContainer'}
        >
          <Grid item xs={12} md={5} className={classes.biggerText}>
            <Typography variant="h2" color="primary">
              Active Reviews
            </Typography>
            <p>
              This page contains links to public reviews that are currently
              active.
            </p>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => navigate('/reviews/')}
              disableElevation
            >
              See All Public Reviews
            </Button>
          </Grid>


          <Grid item xs={12} md={7}>
            {isFetchingBoards ? (
              <div>
                <Skeleton variant="text" height={40} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="text" height={40} />
              </div>
            ) : (
              getListOfBoards()
            )}
          </Grid>

        </Grid>
      </Container>
    </>
  )
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  setHomeView: PropTypes.any,
}

export default Home;