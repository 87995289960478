import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Box, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    display: 'flex',
    alignItems: 'center'
  },
  hr: {
    margin: '0',
    width: '100%',
    border: '1px #bbbbbb17 solid',
  },
  boardListItemHeader: {
    color: '#39A9B0',
  },
  accordionDetails: {
    backgroundColor: '#F5FAFB',
    flexWrap: 'wrap',
    padding: '0px',
  },
  accordionDetailsBox: {
    width: '100%',
    marginTop: '0.9rem',
  },
  boardImage: {
    maxWidth: '34px',
  },
  link:{
    '&:link': {
      color: '#39A9B0'
    },
    '&:hover':{
      color: '#1E678F'
    },
    '&:active':{
      color: '#1e678fb5'
    },
    '&:visited':{
      color: '#1E678F'
    }
  }
}))

const EngagementAccordion = (props:any) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const {
    itemData,
    key,
    open,
  } = props;

  useEffect(()=>{
    setIsOpen(open);
  },[open]);

  return (
    <Grid item key={key}>
      <Accordion
        key={key}
        expanded={isOpen}
        onClick={()=>setIsOpen(!isOpen)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize={"large"} />}
          style={{ margin: '0px' }}
        >
          <div className={classes.accordionSummary}>
            {itemData?.image &&
              <div style={{ marginRight: '1rem' }}>
                <img alt={itemData.image.originalname} src={`data:${itemData.imageMime};base64,${itemData.imageBase64}`} className={classes.boardImage} />
              </div>
            }
            <div>
              <Typography variant="subtitle1" className={classes.boardListItemHeader}>
                {
                  itemData?.itemUrl !== null
                    ? <a rel="noreferrer" target='_blank' href={itemData.itemUrl} className={classes.link}>{itemData.name}</a>
                    : itemData.name
                }
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {itemData?.engagementItems.map((item, index) => {
            return (
              <>
                <Box key={index} className={classes.accordionDetailsBox}>
                  <p style={{ paddingLeft: '1.8rem' }}>
                    {item.itemTitle}
                  </p>
                </Box>
                <hr className={classes.hr} />
              </>
            )
          })}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

EngagementAccordion.propTypes = {
  itemData: PropTypes.any,
  key: PropTypes.any,
  open: PropTypes.bool,
}


export default EngagementAccordion;